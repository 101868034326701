.navbar {
  background-color: $color-primary;
  box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.1);
}
.navbar-position {
  width: 100%;
  justify-content: flex-end;
}
.logo {
  width: 150px;
  height: auto;
  margin-left: 20px;
}
.navbar-toggler {
  margin-right: 20px;
  border: none;
}

.navbar-toggler-icon {
  width: 40px;
}

.nav_link {
  margin-right: 5px;
  font-family: $font-primary;
  font-size: 14px;
  font-weight: 400;
  padding: 20px;
  color: $color-white !important;
  cursor: pointer;
}

.nav_link:hover {
  color: $color-black !important;
  text-decoration: none;
  background-color: $color-white;
}
.nav_link:active {
  color: red;
}

.nav_link_profile:hover {
  color: $color-white !important;
}
.admin-btn {
  background-color: $color-secondary;
  border: none;
  color: $color-black;
  text-align: start;
  padding: 20px;
  text-decoration: none;
  display: inline-block;
  font-size: 14px;
  font-family: $font-primary;
}
.admin-btn:hover {
  color: $color-link !important;
  text-decoration: none;
}
@media (min-width: 992px) {
  .admin-btn {
    border-radius: 50px;
    padding: 5px;
  }
  .nav_link:hover {
    color: $color-secondary !important;
    background-color: $color-primary;
  }
}
