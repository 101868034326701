html,
body {
  height: 100%;
  position: relative;
}

#root {
  height: 100%;
  display: flex;
  flex-direction: column;
}

body {
  background: $color-primary;
  margin: 0;
}
.body-container {
  width: 90%;
  margin: 0 auto;
}
footer {
  background: $color-footer;
  color: $color-white;
}

.wrapper {
  flex: 1 0 auto;
}
