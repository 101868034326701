.header {
  width: 80%;
  z-index: 100;
  position: relative;
}
.hide {
  display: none;
}
.header-content {
  position: absolute;
  top: 200px;
  left: 40px;
}
.button {
  background-color: $color-secondary;
  width: 150px;
  padding: 10px;
  text-align: center;
  color: $color-white;
  border-radius: 2px;
  cursor: pointer;
}
.lottie_animation {
  position: relative;
  top: 0;
  display: none;
}

@media (min-width: 600px) {
  .header {
    width: 60%;
  }
  .header-content {
    top: 250px;
    left: 0px;
  }
}
@media (min-width: 800px) {
  .header {
    width: 40%;
  }
}
@media (min-width: 1200px) {
  .lottie_animation {
    display: block;
    z-index: 1000;
  }
}
