// Colors
$color-primary: #0b2041;
$color-secondary: #f9ac46;
$color-contrast: #f9ac46;
$color-background: rgba(11, 32, 65, 0.8);
$color-footer: #0b2041;
$color-white: #ffffff;
$color-black: #000;
$color-shadow: rgba(0, 0, 0, 0.1);
$color-link: #f9ac46;

// Fonts

$font-primary: "Poppins", sans-serif;
$font-secondary: "Lato", sans-serif;
