.contact-container {
  margin: auto;
  background-color: $color-background;
  z-index: 100;
}

.contact-col {
  align-self: center;
  padding: 40px;
}

.form-col {
  padding: 40px;
}
.form_input {
  display: flex;
  border: none;
  background-color: $color-white;
  min-width: 300px;
  margin: 10px 0px;
  font-family: $font-primary;
  font-size: 14px;
  padding: 5px;
  outline: none;
}
#message {
  min-height: 150px;
}
.form-button {
  width: 300px;
  padding: 5px;
  margin: px 0px;
  font-family: $font-primary;
  font-size: 14px;
  text-align: center;
  color: $color-white;
  background: $color-secondary;
  border: none;
}
.setFormError {
  font-family: $font-secondary;
  font-size: 12px;
  color: red;
  // background-color: $color-white;

  padding: 3px;
}

.setFormSuccess {
  font-family: $font-secondary;
  font-size: 12px;
  color: green;
  // background-color: $color-white;
  padding: 3px;
}
