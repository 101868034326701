.team-container {
  margin: 70px auto;
  text-align: center;
  z-index: 100;
}

.profile_pic {
  width: 80%;
  border: 2px solid $color-white;
}

.title {
  color: $color-secondary;
  margin: 7px 0px 2px 0px;
}
.global-class-name {
  margin: 0px 5px;
}
.team-col {
  text-align: center;
  margin-bottom: 30px;
}
