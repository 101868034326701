.vacancies-container1 {
  margin-top: 200px;
  z-index: 1000;
  background-color: $color-background;
}
.vacancies-container {
  z-index: 1000;
  background-color: $color-background;
}
.vacancies-col {
  padding: 20px;
  align-self: center;
}
li {
  font-family: $font-primary;
  font-size: 16px;
  color: $color-white;
  font-weight: 400;
}
