.tech-container {
  margin-top: 20px;
  z-index: 100;
  background-color: $color-background;
}
.tech-col {
  padding: 20px;
  align-content: center;
  z-index: 100;
}
