.about-container {
  margin-top: 110vh;
  z-index: 100;
  background-color: $color-background;
}
.about-col {
  padding: 20px;
}

.flex-image-col {
  align-self: center;
  text-align: center;
  margin-bottom: 20px;
}
.background-logo-image {
  width: 200px;
  opacity: 0.6;
  margin: auto;
  display: none;
}
@media (min-width: 800px) {
  .background-logo-image {
    display: block;
  }
}
