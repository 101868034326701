.loading_background {
  position: absolute;
  background-color: $color-primary;
  z-index: 1500;
  width: 100%;
  height: 100%;
  z-index: 1500;
}

.spinner {
  position: absolute;
  top: 50%;

  left: 50%;
  margin-top: -55px;
  margin-left: -50px;
  width: 100px;
  height: 110px;
  animation-name: spin;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  z-index: 2000;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
