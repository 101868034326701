.vision-container {
  margin-top: 20px;
  z-index: 100;
  background-color: $color-background;
}

.vision_col {
  padding: 20px;
}
.macbook_col {
  align-self: center;
  text-align: center;
}
.macbook {
  margin: auto;
  width: 350px;
}
@media (min-width: 768px) {
  .vision-container {
    display: flex;
    justify-content: space-between;
  }
}
