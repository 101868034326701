h1 {
  font-family: $font-primary;
  font-size: 36px;
  color: $color-white;
}
h2 {
  font-family: $font-primary;
  font-size: 12px;
  color: $color-secondary;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 1px;
}
h3 {
  font-family: $font-primary;
  font-size: 22px;
  font-weight: 400;
  color: $color-white;
}
a {
  color: $color-link;
  text-decoration: none;
  font-family: $font-primary;
  font-size: 14px;
}
p {
  font-family: $font-primary;
  font-size: 16px;
  color: $color-white;
  font-weight: 400;
}
